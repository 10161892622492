.TableField {
  border-width: 0px;
}

.TableField legend {
  font-weight: bold;
  margin: auto;
  top: 0; left: 0; right: 0;
}

.DomainTable {
  font-family: verdana,arial,sans-serif;
  font-size:11px;
  color:#333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
  margin: auto;
  top: 0; left: 0; right: 0;
}

.DomainTable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #dedede;
}

.DomainTable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}
